import {graphql, Link, StaticQuery} from "gatsby"
import React, {useContext} from "react"
import {getMenusBottom, getMenusTop} from './menus'
import Img from "gatsby-image"
import {slide as Menu} from 'react-burger-menu'
import styles from './burger-menu-styles'
import fontBebasKaiWoff2 from '../fonts/BebasKai.woff2'
import fontSemplicitaLight from '../fonts/semplicita.light.otf'
import fontSemplicitaMedium from '../fonts/semplicita.medium.otf'
import {Helmet} from "react-helmet";
import {UserContext} from "../context/UserContext";

const MenuItem = ({item: m}) => <li className='mr-5' key={m.label}>
  <Link className='text-black no-underline font-sans font-light'
        activeClassName='text-green-600' to={`${m.href}/`} partiallyActive={true}>
    {m.label}
  </Link>
</li>

const Header = () => {
  const userContext = useContext(UserContext);
  const menus = getMenusTop(userContext.user !== null)
  const half_length = Math.ceil(menus.length / 2);
  const menu1 = menus.slice(0, half_length)
  const menu2 = menus.slice(half_length)


  return <StaticQuery
    query={graphql`
    query Header {
        datoCmsVariousfragment {
            headerlogo {
                fixed(width: 231, height: 22) {
                    ...GatsbyDatoCmsFixed
                }
            }
        }
    }
    `}
    render={(data) => {
      return <div style={{zIndex: 1100}}>
        <Helmet>
          <link rel='preload' as='font' href={fontBebasKaiWoff2} type='font/woff2' crossOrigin='anonymous'/>
          <link rel='preload' as='font' href={fontSemplicitaLight} type='font/otf' crossOrigin='anonymous'/>
          <link rel='preload' as='font' href={fontSemplicitaMedium} type='font/otf' crossOrigin='anonymous'/>
        </Helmet>
        <header
          className='bg-white w-screen flex items-center self-center content-center justify-between px-4 py-4 border-b border-grey'>
          <ul className='list-reset hidden lg:flex justify-start flex-1 items-center'>
            {menu1.map(m => <MenuItem item={m} key={m.label}/>)}
          </ul>
          <Link className='text-center md:text-center flex-grow-0' to="/">
            <Img className="" fixed={data.datoCmsVariousfragment.headerlogo.fixed}/>
          </Link>
          <ul className='list-reset hidden lg:flex justify-end items-center flex-1'>
            {menu2.map(m => <MenuItem item={m} key={m.label}/>)}

            {userContext.user ?
              <Link
                className='text-black no-underline font-sans font-light mr-2'
                activeClassName='text-green-600' to='/myaccount/mylookbook/'
                partiallyActive={true}>{userContext.getFullName()}</Link> :
              null}
            <Link to='/booking/'
                  className='py-1 px-2 focus:outline-none bg-yellow-700 font-sans font-light text-white text-center tracking-wide text-lg'>Book
              now!</Link>
          </ul>
        </header>
        <div className='lg:hidden'>
          <Menu className='' styles={styles}>
            {[...menus, ...getMenusBottom()].map(m =>
              <Link className='outline-none text-white no-underline font-sans font-light my-1'
                    style={{display: 'block'}}
                    to={m.href + '/'} key={m.href}>
                {m.label}
              </Link>
            )}
            {userContext.user && <Link className='outline-none text-white no-underline font-sans font-light my-1'
                                       style={{display: 'block'}}
                                       to='/myaccount/mylookbook/'>{userContext.getFullName()}</Link>}
            <Link to='/booking/'
                  className='py-1 px-2 focus:outline-none bg-yellow-700 font-sans font-light text-white text-center tracking-wide text-lg'>Book
              now!</Link>
          </Menu>
        </div>
      </div>;
    }}
  />

}

export default Header
