import React from "react"

import Header from "./header"
import Footer from "./footer"
import NewsLetter from "./newsletter";

const Layout = ({children}) => (
  <div>
    <Header/>
    <div>
      <main>{children}</main>
    </div>
    <NewsLetter/>
    <Footer/>
  </div>

)


export default Layout
