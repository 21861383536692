export const getMenusTop = (authenticated) => {
  const extra = authenticated ?
    [] :
    [
      {href: '/register', label: 'Register'},
      {href: '/login', label: 'Sign in'}
    ]
  return [
    {href: '/cities', label: 'Shopping Cities'},
    {href: '/services', label: 'Services'},
    {href: '/stylists', label: 'Stylists'},
    {href: '/shopping-packages', label: 'Shopping Packages'},
    {href: '/partners', label: 'Partners'},
    {href: '/lookbook', label: 'LookBook'},
    {href: '/blogs', label: 'Blog'},
    ...extra,
  ];
}

export const getMenusBottom = () => {
  return [
    {href: '/aboutus', label: 'About'},
    {href: '/contactus', label: 'Contact Us'},
    {href: '/privacypolicy', label: 'Privacy Policy'},
    {href: '/press', label: 'Press'},
  ];
}


