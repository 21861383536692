import React from "react"
import {HelmetDatoCms} from "gatsby-source-datocms";
import Helmet from "react-helmet";

const SEO = ({title, seo, image, children}) => {
  if (!seo) {
    return <Helmet title={title}/>
  }
  const tags = seo.tags.map(t => {
    if (t.tagName === 'title') {
      if (title) {
        return {...t, content: `${title} | LookStyler`}
      } else {
        return t
      }
    }
    if (t.tagName === 'meta') {
      const as = t.attributes;
      if (as.property === 'og:title' || as.name === 'twitter:title') {
        return {...t, attributes: {...as, content: `${title || as.content} | LookStyler`}}
      }
      if (as.property === 'og:image' || as.name === 'twitter:image') {
        return {...t, attributes: {...as, content: `${image || as.content}?fit=crop&h=627&w=1200`}}
      }
      return t
    }
    return t;
  })
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "LookStyler",
    url: "https://www.lookstyler.com",
    logo: "https://www.datocms-assets.com/10110/1552464165-lookstyler-secondary-mark-black.png"
  }

  return <>
    <HelmetDatoCms seo={{tags}}>{children}</HelmetDatoCms>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  </>;
}


export default SEO

