import {graphql, Link, StaticQuery} from "gatsby";
import React, {useEffect, useState} from "react";
import {getMenusBottom} from './menus'
import Img from "gatsby-image";


const Iets = () => {
  const menus = getMenusBottom()
  const [width, setWidth] = useState(1000)

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  })

  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  return (<StaticQuery
    query={graphql`
      query Footer {
             datoCmsHomepage {
              logo {
                  fixed(width: 231, height: 22, imgixParams: { auto: "compress,format" }) {
                      ...GatsbyDatoCmsFixed
                  }
              }
              }
      }
      `}
    render={(data) =>
      width <= 1024 ?
        null :
        <footer className='flex justify-between bg-black flex-wrap py-2'>
          <ul className='flex px-4'>
            {menus.map(m => <Link key={m.label} className='text-white font-sans font-light my-1 no-underline mr-8'
                                  to={m.href + '/'}>{m.label}</Link>)}
          </ul>
          <Link className='py-1 mx-4' to="/">
            <Img className="" fixed={data.datoCmsHomepage.logo.fixed}/>
          </Link>
        </footer>
    }
  />);
}
export default Iets