import React, {useState} from "react"
import {Formik} from "formik";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
// import Recaptcha from 'react-recaptcha'
import {Location} from '@reach/router'
import {client} from '../apollo/client';


const NewsLetter = () => {
  const [status, setStatus] = useState('')

  const INSERT_NEWSLETTER_SUBSCRIPTION = gql`
    mutation InsertNewsletterSubscription($input:InsertNewsletterSubscriptionInput!) {
      insertNewsletterSubscription(input: $input)
    }
  `


  return <div className="flex flex-col justify-center items-center content-center mb-4">
    <span className="font-sans text-center text-purple-800 text-2xl tracking-wide m-1">Subscribe to our Newsletter!</span>
    <Location>
      {({location}) => {
        return < Mutation
          client={client}
          mutation={INSERT_NEWSLETTER_SUBSCRIPTION}>
          {(insertNewsletterSubscription) =>
            (<Formik
                initialValues={{email: ''}}
                validate={values => {
                  let errors = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={async ({recaptcha, ...input}, {setSubmitting, resetForm}) => {
                  setStatus('')
                  // sessionStorage.setItem('recaptcha', recaptcha)
                  insertNewsletterSubscription({variables: {input}})
                    .then((d) => {
                      setSubmitting(false)
                      resetForm()
                      setStatus('ok')
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        'event': 'signUpFormSuccess',
                        'formId': 'ppForm',
                        'eventCategory': 'Subscriptions',
                        'eventAction': 'Pop-up form success',
                        'eventLabel': location.pathname
                      });
                    })
                    .catch(e => {
                      console.error(e)
                      setSubmitting(false)
                      setStatus('error')
                    })
                }}
              >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                  return (
                    <form className='flex flex-wrap justify-center w-full mx-4' onSubmit={handleSubmit}>
                      <div className="flex flex-col justify-center mx-2 w-full md:w-1/2">
                        <input
                          className='focus:outline-none border-solid border-2 border-black p-2'
                          type="email"
                          name="email"
                          placeholder='Email'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <p className="text-red-700 text-xs">
                          {errors.email && touched.email && errors.email}
                        </p>
                        <p
                          className={"mt-2 font-sans font-light px-2 py-2 " + (status === '' ? 'hidden ' : '') + (status === 'ok' ? 'bg-green-200 text-green-800 ' : 'bg-red-200 text-red-800 ')}>{status === 'ok' ? 'Thank you for subscribing' : 'Something went wrong, please try again later'}</p>
                      </div>
                      <button
                        className='newsletter bg-yellow-700 font-title tracking-wide text-white px-4 py-2 ml-2 border-white border-2 b'
                        type="submit"
                        disabled={isSubmitting}>Subscribe
                      </button>
                    </form>
                  );
                }}
              </Formik>
            )
          }
        </Mutation>

      }}
    </Location>

  </div>;
};
export default NewsLetter
